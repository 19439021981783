.ranking-page {
    background-color: #eee;
    margin: 0 -15px;
    padding: 0 15px;
    min-height: 100vh;

    &__header {
        overflow: hidden;

        &__title {
            display: block;
            text-transform: uppercase;
            color: #333;
            margin-bottom: 5px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 700;
        }
    }

    &__list {
        margin: 0 -15px;
        padding: 0;
        margin-bottom: 15px;
        background-color: #ddd;
        transition: height 0.2s ease-in-out;
    }

    &__item {
        padding: 5px 15px;
        list-style-type: none;
        border-top: 1px solid #afafaf;
        display: flex;
        height: 80px;
        align-items: center;
        background: white;
        transition: margin-top 0.2s ease-in-out;

        &:last-child {
            border-bottom: 1px solid #afafaf;
        }

        &__rank {
            width: 25px;
            font-weight: 700;
            font-size: 24px;
            color: #444;
        }

        &__photo {
            height: 70px;
            width: 70px;
            object-fit: contain;
            margin: 0 10px;
        }

        &__details {
            flex: 1;
        }

        &__name {
            display: block;
            font-weight: 500;
        }

        &__carrier {
            display: block;
            font-size: 14px;
            color: #666;
        }

        &__drag {
            .fas {
                padding: 5px;
                padding-right: 10px;
                font-size: 24px;
            }
        }
    }
}

body > li.ranking-page__item {
    .ranking-page__item__rank {
        color: transparent;
    }
}
