.navigation {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__user {
        display: flex;
        align-items: center;

        &__avatar {
            border-radius: 50%;
            height: 40px;
            width: 40px;
        }

        &__name {
            font-size: 14px;
            margin-right: 10px;
        }
    }

    &__loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        flex-direction: column;

        .fa-spinner {
            font-size: 30px;
        }

        p {
            font-weight: 500;
        }
    }
}
