.app {
    padding: 0 15px;

    &__login {
        &__button {
            display: block;
            width: 100%;
        }
    }
}
