.leaderboard-page {
    background-position: center;
    background-size: cover;
    margin: 0 -15px;
    padding: 30px;
    min-height: 100vh;
    color: white;

    &__title {
        margin-top: 0;
        font-size: 50px;
        display: flex;
        align-items: center;

        button {
            margin-left: 20px;
        }

        .fa-spin {
            margin-left: 15px;
            font-size: 20px;
        }
    }

    &__list {
        display: flex;
        flex-flow: column wrap;
        max-height: 80vh;
        width: 90vw;
        overflow: auto;
        align-content: flex-start;
        margin: auto;
    }

    &__item {
        padding: 5px 15px;
        list-style-type: none;
        border-top: 1px solid #afafaf;
        display: flex;
        height: 11vh;
        align-items: center;
        background: white;
        transition: margin-top 0.2s ease-in-out;
        margin: 7.5px 15px;
        width: calc(33% - 30px);
        border-radius: 10px;

        &:last-child {
            border-bottom: 1px solid #afafaf;
        }

        &__rank {
            width: 60px;
            font-weight: 700;

            color: #444;

            &__placement {
                font-size: 35px;
            }

            &__score {
                font-size: 18px;
                font-weight: 400;
            }
        }

        &__photo {
            height: 9vh;
            width: 9vh;
            object-fit: contain;
        }

        &__details {
            flex: 1;
        }

        &__name {
            display: block;
            font-weight: 500;
            font-size: 25px;
            color: #333;
        }

        &__carrier {
            display: block;
            font-size: 20px;
            color: #666;
        }

        &__drag {
            .fas {
                padding: 5px;
                padding-right: 10px;
                font-size: 24px;
            }
        }
    }
}

body > li.ranking-page__item {
    .ranking-page__item__rank {
        color: transparent;
    }
}
